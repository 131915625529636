<template src="@/../../../templates/brand/components/templates/shop/Program.html"> </template>

<script>
import _ from 'lodash/fp'
import { mapActions, mapState, mapGetters } from 'vuex'
import mainNav from '@/components/MainNav.vue'
import productDetailTile from '@/components/shop/product/ProductDetailTile.vue'
import productTile from '@/components/shop/product/ProductTile.vue'
import countDown from '@/components/Countdown.vue'
import Modal from '@/components/Modal.vue'
import kramed from 'kramed'
kramed.setOptions({
  renderer: new kramed.Renderer(),
  gfm: true,
  langPrefix: '',
  tables: true,
  breaks: false,
  pedantic: false,
  sanitize: true,
  smartLists: true,
  smartypants: true
})

// logic
export default {
  name: 'Program',
  components: { mainNav, productTile, productDetailTile, countDown, Modal },
  data () {
    return {
      excludeCats: [],
      filterCategories: [],
      loaded: false,
      list: 0,
      showModal: false,
      modalTitle: this.$t('modal.order.title'),
      modalBody: '',
      modalFlash: '',
      modalActions: [],
      modalButtons: [
        {
          text: this.$t('modal.order.continue'),
          class: 'primary',
          handler () {
            return true
          }
        },
        {
          text: this.$t('modal.order.cancel'),
          class: 'reject',
          handler () {
            return false
          }
        }
      ],
      modalCallback: this.modalcb
    }
  },
  computed: {
    ...mapState({
      program: ({ Collection }) => Collection.program || { content: { en: {}, fr: {} } },
      progproducts: ({ Product, Collection }) => {
        return Collection.program.settings &&
          Collection.program.settings.layout === 'standard'
          ? _.sortBy(p => p.order, Product.listFamilies)
          : _.sortBy(p => p.order, Product.atomicFamilies)
      },
      collection: ({ Collection }) => Collection.collection,
      user: ({ App }) => App.user
    }),
    ...mapGetters({ appconfig: 'const' }),
    preorders () {
      return _.keys(this.user.profile.preorders.details).reduce(
        (acc, i) => {
          if (
            this.user.profile.preorders.details[i].url === this.program.url
          ) {
            acc.details[i] = this.user.profile.preorders.details[i]
            acc.items[i] = this.user.profile.preorders.items[i]
          }
          return acc
        },
        { items: {}, details: {} }
      )
    },
    products () {
      if (_.isEmpty(this.progproducts)) return []
      return _.reduce(
        (acc, p) => {
          acc.push(p)
          // if (!p.items) acc.push(p)
          // else if (
          //   ~['family', 'variant', 'variation'].indexOf(p.relatedBy.toLowerCase())) {
          //   acc.push(p)
          // } else acc = acc.concat(p.items)
          return acc
        },
        [],
        this.progproducts
      )
    },
    filteredProducts () {
      if (_.isEmpty(this.products)) return []
      if (!this.filterCategories.length) return this.products
      return _.filter(p => {
        if (!this.filterCategories.length) return true
        let _cats = _.map(c => (c && c.url) || null, p.category)
        return _.every(c => ~_cats.indexOf(c), this.filterCategories)
      }, this.products)
    },
    departments () {
      const _cats = _.flatten(_.map(p => p.category, this.products))
      const _urls = _.uniq(_.map(c => c.url, _cats))
      return _.map(c => _.find(i => i.url === c, _cats), _urls)
    },
    noUserItems () {
      if (_.isEmpty(this.preorders.items)) return true
      return !_.sum(
        _.map(i => this.preorders.items[i], _.keys(this.preorders.items))
      )
    },
    userHasSelections () {
      if (_.isEmpty(this.preorders.items)) return false
      return _.sum(_.values(this.preorders.items))
    }
  },
  methods: {
    ...mapActions(['getProgram', 'getProgramProducts']),
    orderAgreement () {
      this.showModal = true
      this.$emit('show', true)
    },
    closeModal () {
      this.showModal = false
    },
    modalcb (val, coll, close) {
      if (!val.res) close()
      else {
        close()
        this.$router.push({
          name: 'reserve',
          params: { program: this.program.url },
          query: { cartid: 'preorders'}
        })
      }
    },
    filterDepts: () => true
  },
  destroyed () {
    this.loaded = false
  },
  watch: {
    '$route': function (n, o) {
      console.info(' :: route watcher', n, o)
      if (this.$route.params.cat1) {
        this.filterCategories = typeof this.$route.params.cat1 === 'string' ? this.$route.params.cat1.split('/') : this.$route.params.cat1
      } else {
        this.filterCategories = []
      }
    }
  },
  created () {
    this.getProgram({ id: this.$route.params.program, key: 'url' })
      .then(data => {
        if (!data.data || !data.data.active || new Date (data.data.ends) < new Date() || new Date (data.data.starts) > new Date() || data.data.complete) {
          this.$router.push('/')
          return
        }
        return this.getProgramProducts({
          id: this.$route.params.program,
          key: 'url',
          list: data.data.settings && data.data.settings.layout === 'standard'
        })
      })
      .then(() => {
        this.loaded = true
      })
      .catch(err => {
        console.error(' ::: error getting program:', err)
        this.$router.push('/')
      })

    if (this.$route.params.cat1) {
      // this.filterCategories = this.$route.params.cat1.split('/')
      this.filterCategories = typeof this.$route.params.cat1 === 'string' ? this.$route.params.cat1.split('/') : this.$route.params.cat1
    } else {
      this.filterCategories = []
    }
  },
  mounted () {
    this.modalBody = this.appconfig.orderAgreement[this.$i18n.locale]
  }
}
</script>

<style src="@/../../../templates/brand/components/styles/shop/Program.css"></style>
